<template>
  <div :class="inputContainerClass">
    <v-textarea
    :label="label"
      :id="id"
      :name="name"
      :ref="name"
      :class="inputClass"
      :placeholder="placeHolder"
      v-model="content"
      @input="handleInput(content)"
></v-textarea>
  </div>
</template>
<script>
import InputCommons from "./InputCommons.vue";
export default {
  extends: InputCommons
};
</script>
