<template>
  <fieldset :class="inputContainerClass">
  <legend>{{label}}</legend>
    <p v-for="option in dataSource" :key="option.id">

        <v-checkbox
          :id="id+'-'+option.id"
          :label="option.value"
          :name="id+'-'+option.id"
          :ref="id+'-'+option.id"
          :class="inputClass"
          :value="option.id"
          @click="setValue(option.id, $event)"
          v-model="content[option.id]"
        >
        </v-checkbox>
    </p>
  </fieldset>
</template>
<script>

import InputCommons from "./InputCommons.vue";
import multiCheckBox from "@src/js/vue/include/FormCreator/mixins/multiCheckBox.js";
export default {
  extends: InputCommons,
  mixins: [
  multiCheckBox,
    ],
    
};
</script>
