<template>
    <div class="acquisti-sospesi">
        <msp-app-bar></msp-app-bar>
        <div class="acquisti-sospesi__container" >
            <acquisto-sospeso
                    v-for="purchase in purchases"
                    :key="purchase.id"
                    :acquisto="purchase"
                    @open="openModal"
                    />

        </div>
<v-dialog
v-model="showModal"
fullscreen
hide-overlay
transition="slide-x-transition"
:return-value="'detail'"
>
<router-view></router-view>
</v-dialog>    </div>
</template>

<script type="module">
import mspAppBar from "@components/MspAppBar.vue";
import {  mapState } from 'vuex';
import api from "@mixins/api.js";
import env from "@mixins/environment.js"
import acquistoSospeso from "@components/AcquistoSospeso.vue"

const component = {
    mixins: [
    env,
    ],
    components: {
        mspAppBar,
        acquistoSospeso,
    },
    props: [
    ],
    computed: {
    ...mapState(
      {
        dialog: (state)=>state.acquisto.dialog,
      }
    ),
    },
    watch: {

        dialog: function (val) {
            this.showModal = val;
        },

        $route: {
            immediate: true,
            handler: function(newVal) {
                this.showModal = newVal.meta && newVal.meta.showModal;
            }
        }
    }, 
    data() {
        return {
            purchases: [],
            showModal: false,
        }
    },

    mounted() {
        this.init();
    },

    methods: {

        init:  function () {
            this.loadPurchases();
        },

        openModal: async function (item) {
            this.$store.dispatch("acquisto/setDialog",true);
            let acquisto_id = item.acquisto_id;
            this.$router.push("/acquisti/sospesi/form/"+acquisto_id,true);
        },

        getPurchase: function (id) {
            return this.purchases.find(el=>el.id==id);
        },

        loadPurchases: async function () {
            let url = this.api_base_url+"/api/v2/ecommerce.php?acquisti_sospesi";
            const result = await api(url, 'GET', null);
            if (result.success) {
                this.purchases = result.data;
            }
            return Promise.resolve(result);
        },

        getFormFromAcquistoId: async function (id) {
            let url = this.api_base_url+"/api/v2/ecommerce.php?form&acquisto_id="+id;
            const result = await api(url, 'GET', null);
            if (result.success) {
            return Promise.resolve(result.data);
            } else {
                return Promise.resolve(null);
            }
        },
    }
}
    export default component;
</script>

<style lang="scss">
.acquisti-sospesi {
    .v-dialog {
        .v-application--wrap
        {
            padding: 56px 0 !important;
        }
    }

    .acquisti-sospesi__container {
        padding: 20px;
    }
}
</style>
