<template>
<form>
    <div :class="validationsContainerClass">
      <div
        v-for="(i, index) in schema"
        :key="index"
        :class="validationContainerClass"
      >
          <component
            v-bind:is="bestInput(i.type)"
            :type="i.type"
            :label="i.label"
            :name="i.key"
            :id="i.key"
            :classes="i.classes"
            v-model="content[i.key]"
            :rules="i.rules"
            :place-holder="i.placeHolder"
            :input-container-class="inputContainerClass"
            :input-class="inputClass"
            :data-source="i.dataSource"
            :data-text-field="i.dataTextField"
            :data-value-field="i.dataValueField"
            @input="handleInput()"
          ></component>
          
      </div>
    </div>
    <v-btn @click="onSubmit" block large color="primary">
      <span >{{submitText}}</span>
      </v-btn>
</form>
</template>
<script>
import TextInput from "./TextInput.vue";
import TextArea from "./TextArea.vue";
import DateInput from "./DateInput.vue";
import CheckBox from "./CheckBox.vue";
import MultiCheckBox from "./MultiCheckBox.vue";
import Radio from "./Radio.vue";
import Info from "./Info.vue";
export default {
  props: {
      submitText: {
          type: String,
          default: 'Invia'
      },
    value: {
      default: {}
    },
    /**
     * Action on submit
     */
    onSubmit: {
      type: Function
    },
    /**
     * Form Schema
     */
    schema: {
      type: Array
    },
    validationsContainerClass: {
      type: String
    },
    validationContainerClass: {
      type: String
    },
    inputContainerClass: {
      type: String
    },
    inputClass: {
      type: String
    }
  },
  watch: {
    value: {
      handler(val) {
        this.content = val;
      },
      deep: true
    }
  },
  data() {
    return {
      content: this.value
    };
  },
  components: {
      TextInput,
      TextArea,
      DateInput,
      CheckBox,
      MultiCheckBox,
      Radio,
      Info,
  },

  methods: {
    handleInput() {
      this.$emit("input", this.content);
    },
    /**
     * Validate Form
     * access it by $refs
     */
    validate() {
      return this.$el.forms[0].validate()
    },

    bestInput(type) {
      if (type == "textarea") return TextArea;
      if (type == "checkbox") return CheckBox;
      if (type == "multicheckbox") return MultiCheckBox;
      if (type == "radio") return Radio;
      if (type == "date") return DateInput;
      if (type == "info") return Info;
      return TextInput;
    }
  }
};
</script>
