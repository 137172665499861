<template>
  <div :class="inputContainerClass">
        <label >
        <input
          :id="id"
          :name="name"
          :ref="name"
          :type="'checkbox'"
          :class="inputClass"
          :placeholder="placeHolder"
          @input="handleInput(option.id)"
          v-model="content"
        /> {{ label }} </label>
  </div>
</template>
<script>
import InputCommons from "./InputCommons.vue";

export default {
  extends: InputCommons,
};
</script>
