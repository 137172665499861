export default {
    data: function () {
        return {
            content: {},
        }
    },
    watch: {
        value: function () {
        }
    },
    methods: {

        setValue: function () {
            this.handleInput(this.content);
        }
    },
};

