<template>
    <div :class="inputContainerClass">
        <v-dialog
                v-model="editing"
                width="290px"
                persistent
                >
                <template v-slot:activator="{ on, attrs }">

                    <v-text-field
                            :value="convertDate(content)"
                            :label="label"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            ></v-text-field>
                </template>
            <v-date-picker
                    v-model="content"
                    :first-day-of-week="1"
                    locale="it-it"
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                            text
                            @click="editing = false"
                            >
                            Annulla
                    </v-btn>
                        <v-btn
                                color="primary"
                                @click="updateDialog()"
                                >
                                OK
                        </v-btn>
            </v-date-picker>
        </v-dialog>




    </div>
</template>
<script>
import InputCommons from "./InputCommons.vue";
  export default {
      extends: InputCommons,
      mixins: [
      ],
      mounted: function () {
      },
      methods: {
    convertDate (date) {
        if(!date) return "";
      return (new Date(date)).toLocaleDateString("it");
    },

          updateDialog: function () {
              this.editing = false;
              this.handleInput(this.content);
          },
      },
  };
</script>
