<template>
    <div class="msp-form-acquisto" >
        <v-app-bar app>
            <v-btn icon @click="dismissModal()">
                <v-icon>mdi-chevron-left</v-icon>
            </v-btn>

            <v-toolbar-title>
                <div class="test-titolo">
                    <h2>Dati</h2>
                </div>
            </v-toolbar-title>

        </v-app-bar>
        <v-app>

            <v-container  >
                <v-card>
                    <v-card-text>
                    <p>
                       Inserisci i dati necessari per personalizzare il tuo acquisto.
                    </p>
                    <div v-if="schema" class="msp-form-acquisto-container" >

                        <form-creator
                                ref="form"
                                v-model="data"
                                :schema="schema"
                                :on-submit="submitFormAcquisto"
                                validations-container-class="row"
                                validation-container-class="col"
                                input-class=""
                                submit-text="Applica" />
                    </div></v-card-text>
                </v-card>

            </v-container>

        </v-app>
    </div>
</template>

<script>
import api from "@mixins/api.js";
import env from "@mixins/environment.js"
import FormCreator from '@mobilesrc/FormCreator/FormCreator.vue';
const component = {
    components: {
        FormCreator,
    },
    mixins: [
    env,
    ],
    props: [
    ],
    computed: {
    },
    data() {
        return {
            data: {},
            schema: null,
        }
    },
    mounted: function () {
        this.init();
    },

    methods: {
        init: function () {
            let acquisto_id = this.$route.params.id;
            this.acquisto_id = acquisto_id;
            this.loadForm(acquisto_id);
        },

    dismissModal () {
      this.$store.dispatch("acquisto/setDialog", false);
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },

    goToCalendar () {
      this.$store.dispatch("acquisto/setDialog", false);
      this.$router.push('/calendario')
    },

        loadForm: async function (acquisto_id) {
            let url = this.api_base_url+"/api/v2/ecommerce.php?form&acquisto_id="+acquisto_id;
            const result = await api(url, "GET", null);
            if (result.success) {
                this.schema = result.data.fields;
            }
            return Promise.resolve(result);
        },

        alertFormErrors: function (errors, schema = []) {
            let a_message = [];
            a_message.push("Campi mancanti:");
            let keys = Object.keys(errors);
            let required = schema.filter(el=>(keys.indexOf(el.key) !== -1));
            let a_fields = required.map(el=>el.label);
            a_message = [...a_message, ...a_fields];

            const message = a_message.join("\n");
            alert(message);
        },
        submitFormAcquisto: function () {
            const acquisto_id = this.acquisto_id;
            const data = this.data;
            const schema = this.schema;
            let url = this.api_base_url+"/api/v2/ecommerce.php?applica&acquisto_id="+acquisto_id;
            api(url, "POST", data)
                .then((result)=>{
                    if (result.success) {
                        if (result.data.completed) {
                            alert("acquisto applicato con successo");
                            this.goToCalendar();  
                        } else {
                            this.alertFormErrors(result.data.rispostaAcquisto.checkRisposte.errors, schema);
                        }
                    }
                });
        },
        onSubmit: function (ev) {
            ev.preventDefault();
            this.$emit('submit', this.data);
        },
    }
}
export default component;
</script>

<style lang="scss">
    .msp-form-acquisto {
        padding-top: 50px;
        .row {
            flex-direction: column;
        }
    }
</style>
