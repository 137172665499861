<template>
    <div class="msp-test-detail-detail mx-auto" >
    ciao</div>
</template>

<script>
const component = {
    components: {
    },
    mixins: [
    ],
    props: [
    ],
    computed: {
    },
    data() {
        return {
        }
    },
    mounted: function () {
    },

    methods: {

    dismissModal () {
      this.$store.dispatch("acquisto/setDialog", false);
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },

    }
}
export default component;
</script>

<style lang="scss">
</style>
