<template>
  <v-card
  max-width="400"
  class="acquisto-sospeso"
  >
  <v-card-title>{{acquisto.prodotto_descrizione}}</v-card-title>
    <v-card-actions>
      <v-btn @click="open(acquisto)" block large color="primary">
      <span >Applica</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script type="module">

const component = {
    mixins: [
    ],
    components: {
    },
    props: [
    'acquisto',
    ],
    computed: {
    },
    data() {
        return {
        }
    },
    mounted() {
    },
    methods: {
        open: function (acquisto) {
            this.$emit('open', acquisto);
        },
    }
}
    export default component;
</script>

<style lang="scss">
.acquisto-sospeso {
    --background-testo: var(--background-message);
    --align: left;
    --align-self: self-start;
    --border-radius: 20px 20px 20px 0px;
    display: flex;
    position: relative;
    flex-direction: column;
    width: auto;
    align-self: var(--align-self);
    text-align: var(--align);
    text-align: var(--align);
    margin-bottom: 20px;
    .v-card__title {
        word-break: normal;
    }
}
</style>
