<template>
    <div :class="inputContainerClass">
        <v-text-field
                v-model="content"
                :label="label"
                :placeholder="placeHolder"
                @input="handleInput(content)"
                ></v-text-field>
    </div>
</template>
<script>
import InputCommons from "./InputCommons.vue";
export default {
  extends: InputCommons
};
</script>
