<template>
<div>
    <v-radio-group
                    :label="label"
                    v-model="content"
                    @change="updateField()" 
                    column >
                    <v-radio 
                    v-for="option in dataSource"
                    :key="option.id"
                    :value="option.id"
                    :label="option.value" >
                    </v-radio>
                    
                    </v-radio-group>
</div>
</template>
<script>
import InputCommons from "./InputCommons.vue";

export default {
  extends: InputCommons,
  methods: {
          updateField: function () {
              this.handleInput(this.content);
          },
  },
};
</script>
<style lang="scss">
.form-creator-radio {
    label {
        display: flex;
        align-items: center;
    }

    input {
        margin: 0;
    }
}
</style>
