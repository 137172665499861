<template>
  <div class="form-creator-info" :class="inputContainerClass">
    <p>{{ label }}</p>
  </div>
</template>
<script>
import InputCommons from "./InputCommons.vue";
export default {
  extends: InputCommons
};
</script>
<style lang='scss'>
.form-creator-info {
    p {
        white-space: pre-wrap;
        text-align: left;
        padding: 10px;
    }
}
</style>
